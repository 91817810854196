// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
   height: 100%;
   color: white;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: xx-large;
   overflow-y: scroll; /* Enable vertical scrolling */
   zoom: normal
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;GACG,YAAY;GACZ,YAAY;GACZ,aAAa;GACb,sBAAsB;GACtB,mBAAmB;GACnB,uBAAuB;GACvB,mBAAmB;GACnB,kBAAkB,EAAE,8BAA8B;GAClD;AACH","sourcesContent":[".App {\n   height: 100%;\n   color: white;\n   display: flex;\n   flex-direction: column;\n   align-items: center;\n   justify-content: center;\n   font-size: xx-large;\n   overflow-y: scroll; /* Enable vertical scrolling */\n   zoom: normal\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
